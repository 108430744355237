<template>
  <ui-component-modal
    :modalTitle="'Edit space utilization settings'"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideSaveButton="isSavingError || isSavingSuccess"
    :buttonLabelCancel="isSavingError || isSavingSuccess ? 'Close' : 'Cancel'"
    :onClickCancel="onClickCancel"
    :onClickSave="saveYieldSettings"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table
        v-if="mYieldSettings.MeetingtypeId === 1"
        class="table is-fullwidth"
      >
        <tbody>
          <tr v-if="mYieldSettings.ApplySpaceUtilization">
            <td width="100">0% utilization</td>
            <td>
              <vue-slider
                v-model="values"
                direction="rtl"
                :min="0"
                :max="200"
                :minRange="1"
                :interval="1"
                :tooltip-formatter="formatter"
              ></vue-slider>
            </td>
            <td width="100">100% utilization</td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import yieldProvider from '@/providers/yield'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  components: {
    VueSlider
  },

  props: {
    showModal: {
      type: Boolean,
      default: false
    },

    onClickCancel: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      formatter: v => `${v - 100}%`,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mYieldSettings: null,
      values: []
    }
  },

  computed: {
    ...mapState('yieldStore', ['yieldSettingsState'])
  },

  created() {
    this.mYieldSettings = JSON.parse(
      JSON.stringify(this.yieldSettingsState.yieldSettings)
    )

    this.values = this.spaceValues()
  },

  methods: {
    ...mapMutations('yieldStore', ['setYieldSettingsState']),

    spaceValues() {
      return [
        this.roundToZero(this.mYieldSettings.SpaceUtilizationBottomValue * 100),
        this.roundToZero(this.mYieldSettings.SpaceUtilizationTopValue * 100)
      ]
    },

    saveYieldSettings() {
      let self = this
      self.isSaving = true

      let bottomValue = self.values[1] / 100
      let topValue = self.values[0] / 100

      self.mYieldSettings.SpaceUtilizationBottomValue = bottomValue
      self.mYieldSettings.SpaceUtilizationTopValue = topValue
      self.yieldSettingsState.yieldSettings = self.mYieldSettings

      yieldProvider.methods
        .updateLocationYieldSettings(self.mYieldSettings)
        .then(response => {
          if (response.status === 204) {
            self.isSavingSuccess = true
            self.setYieldSettingsState(self.yieldSettingsState)

            setTimeout(() => {
              self.onClickCancel()
            }, 1500)
          }
        })
        .catch(error => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },

    roundToZero(num) {
      let self = this
      return +(Math.round(self.roundToTwo(num) + 'e+0') + 'e-0')
    },

    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2')
    }
  }
}
</script>

<style></style>
